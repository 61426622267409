.permissions-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  .permissions-modal-icon {
    margin-bottom: 20px;
  }
  .permissions-modal-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #FF60B7;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
  }
  .permissions-modal-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: #FAFAFA;
    margin-bottom: 20px;
  }
  .permissions-modal-button {
    height: 50px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-top: 10px;
  }
}
