.back-modal-container {
  .back-modal-content {
    max-width: 350px;
    padding: 39px;
    border-radius: 30px;
  }
}
.back-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  .back-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .back-modal-icon {
    margin-bottom: 10px;
  }
  .back-modal-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #FF60B7;
    margin-bottom: 10px;
  }
  .back-modal-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: #FAFAFA;
  }
  .back-modal-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .back-modal-button-skip {
    border: none;
    background: #404144;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #FAFAFA;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      opacity: .8;
    }
    &:active {
      transform: scale(.95);
    }
  }
  .back-modal-button {
    height: 50px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}
