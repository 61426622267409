.create-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: #303030;
  z-index: 0;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 15%;
    z-index: -1;
    background: url('../../assets/images/Pattern.png') #303030;
    background-size: 26px 26px;
  }
}
.content-wrapper{
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .desktop{
    overflow-y: scroll;
    padding: 65px 0 0;
    width: 100%;
    max-height: 100%;
  }
  .mobile{
    padding: 85px 0 20px;
    width: 430px;
  }
  .popup-layout-box{
    bottom: 0;
    left: auto;
    //width: -webkit-fill-available;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    align-self: flex-start;
  }
}
.load-content{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  .popup-layout-box{
    display: none;
  }
}
.create-page-mobile {
  display: none;
}

@media (max-width: 576px) {
  .create-page {
    display: none;
  }
  .create-page-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: #303030;
    z-index: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    //padding-bottom: 80px;
    padding-bottom: 110px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 15%;
      z-index: -1;
      background: url('../../assets/images/Pattern.png') #303030;
      background-size: 26px 26px;
    }
  }
  .create-page-mobile-header {
    box-sizing: border-box;
    background: #1C1D1F;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #28292B;
  }
  .create-page-mobile-header-title-box {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .create-page-mobile-header-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: #FAFAFA;
  }
  .create-page-mobile-header-pagination {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: #FAFAFA;
    opacity: .3;
  }
  .create-page-mobile-header-credits {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: #262729;
    height: 44px;
    padding: 0 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 11px;
    color: #FAFAFA;
  }
  .content-wrapper {
    overflow-y: scroll;
    .desktop {
      padding: 45px 0 50px;
    }
  }
}
