.sidebar{
  background: #1C1D1F;
  height: 100%;
  min-height: 100vh;
  width: 430px;
  border-right: 1px solid #28292B;
  .logo-box{
    height: 64px;
    background: var(--dino-snow-white);
    box-sizing: border-box;
  }
}

@media (max-height: 970px) {
  .sidebar{
    .title-box{
      padding: 20px 40px;
    }
  }
}
