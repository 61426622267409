.mobile-navbar {
  display: none;
}

@media (max-width: 576px) {
  .mobile-navbar {
    box-sizing: border-box;
    display: flex;
    width: 100vw;
    height: 54px;
    background: #1C1D1F;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #28292B;
    position: relative;
    z-index: 1;
    .logo {
      cursor: pointer;
    }
  }
  .mobile-menu-button {
    background: transparent;
    border: none;
    padding: 0;
    width: 24px;
    height: 24px;
    &:active {
      opacity: 0.8;
    }
  }
}
