.tooltip-container {
  position: relative;
  width: 24px;
  height: 24px;
  .tooltip {
    position: absolute;
    width: 230px;
    margin-left: 100%;
    background: transparent;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .tooltip-inner {
      padding: 20px;
      background: #303030;
      border-radius: 16px;
      color: #FAFAFAB3;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
    }
  }
}
