.main-banner {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(91.12% 417.75% at 4.67% 0%, #C5F04D 1.32%, #FFCC00 50%, #FF60B7 100%);
  .main-banner-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #000000;
    text-align: center;
  }
  .main-banner-link {
    color: #000000;
  }
}

@media (max-width: 576px) {
  .main-banner {
    position: static;
    height: auto;
    padding: 10px 20px;
  }
}
