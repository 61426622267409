.navbar {
  background: #1C1D1F;
  display: flex;
  width: 70px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-right: 1px solid #28292B;
  .nav-box {}
  .logo-box {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    .logo {
      cursor: pointer;
    }
  }
  .tabs-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 10px;
      box-sizing: border-box;
      width: 60px;
      height: 60px;
      padding: 4px;
      background-color: transparent;
      border-radius: 10px;
      border: 1px solid transparent;
      color: #FAFAFA;
      opacity: 0.3;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.03em;
      text-align: center;
      text-transform: uppercase;
      transition: 0.3s;
      cursor: pointer;
      &.active {
        opacity: 1;
        background-color: #262729;
        border: 1px solid #404144;
        transition: 0.3s;
      }
      &.pricing {
        opacity: 1;
        color: #FF60B7;
      }
    }
  }
  .title-box {
    .title {

    }
  }
  .menu-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .credits {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      //font-family: Outfit;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #FAFAFA;
    }
  }
  .avatar {
    width: 50px;
    height: 50px;
    cursor: pointer;
    &.logged {
      border-radius: 50px;
      border: 1px solid #FF60B7;
      overflow: hidden;
    }
  }
}
.more-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: .3;
  &.opened {
    opacity: 1;
  }
}
.more-dropdown {
  position: fixed;
  left: 80px;
  bottom: 10px;
  background: #1C1D1F;
  z-index: 2;
  color: #FFFFFF;
  border: 1px solid #28292B;
  border-radius: 20px;
  .dropdown-item {
    padding: 15px 20px;
    &:first-child {
      border-bottom: 1px solid #28292B;
    }
    &:last-child {
      border-top: 1px solid #28292B;
    }
    .dropdown-item-icon {
      width: 24px;
      height: 24px;
    }
    .user-name {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 5px;
    }
    .user-email {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      opacity: .5;
    }
    .logout {
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
    .remove {
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #FF5656;
      cursor: pointer;
    }
  }
}

@media (max-width: 576px) {
  .navbar{
    padding: 10px;
    box-sizing: border-box;
    display: none;
    position: fixed;
    flex-direction: row;
    justify-content: unset;
    border-right: none;
    border-top: 1px solid #28292B;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    .nav-box{
      flex: 1;
      display: flex;
      align-items: center;
      .logo-box{
        display: none;
      }
    }
    .title-box{
      display: none;
    }
    .menu-box {
      .credits{
        display: none;
      }
      .more-button {
        display: none;
      }
      .avatar {
        display: none;
      }
    }
    .tabs-box{
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
      .tab-name {
        min-width: 40px;
      }
      .profile {
        opacity: 1;
        &.active {
          .tab-title {
            opacity: 1;
          }
          .avatar {
            opacity: 1;
          }
        }
        .avatar {
          opacity: .3;
          &.logged {
            opacity: 1;
          }
        }
      }
      .tab-title {
        opacity: .3;
      }
    }
    .avatar {
      width: 24px;
      height: 24px;
    }
  }
  .more-dropdown {
    left: 0;
    bottom: 80px;
    width: 100%;
    box-sizing: border-box;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left: none;
    border-right: none;
    padding: 10px 10px 0;
    .dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 10px;
      border-bottom: 1px solid #28292B;
      &:last-child {
        border-top: none;
        border-bottom: none;
      }
      .dropdown-item-user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
      .dropdown-item-user-credits {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: #262729;
        height: 44px;
        padding: 0 10px;
        border-radius: 10px;
      }
      .dropdown-item-avatar {
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        &.logged {
          border: 1px solid #FF60B7;
        }
      }
      .logout {
        flex: 1;
        justify-content: space-between;
        padding: 0 10px;
      }
      .remove {
        flex: 1;
        justify-content: space-between;
        padding: 0 10px;
      }
    }
  }
  .more-dropdown-header {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .more-dropdown-header-line {
    width: 100px;
    height: 5px;
    background: #FFFFFF;
    border-radius: 10px;
    opacity: .1;
  }
}
