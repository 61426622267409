.terms-container{
  width: 100%;
  height: 100%;
  padding: 100px 0 0 100px;
  box-sizing: border-box;
  background: url('../../assets/images/Pattern.png'),
  rgba(28, 29, 31, 0.8);;
  background-size: 26px 26px;
  background-blend-mode: overlay;
  overflow-y: scroll;
  .terms-text{
    width: 70%;
    color: var(--dino-snow-white);
    h2{
      font-size: 48px;
      color: var(--dino-snow-white);
      line-height: 57.60px;
      font-weight: 400;
    }
  }
}

@media (max-width: 576px) {
  .terms-container{
    padding: 20px;
    .terms-text{
      width: 100%;
      margin-bottom: 200px;
    }
  }
}
