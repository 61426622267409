.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 11;
  height: 82px;
  .notification-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #1c1d1f;
    border-radius: 20px;
    border: 1px solid #28292b;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 30px;
    transform: translateX(-50%);
    box-shadow:
      0 1.51px 2.27px 0 #1c1d1f24,
      0 3.82px 5.73px 0 #1c1d1f34,
      0 7.8px 11.7px 0 #1c1d1f41,
      0 16.06px 24.09px 0 #1c1d1f51,
      0 44px 66px 0 #1c1d1f75;
  }
  .notification-icon {
    width: 42px;
    height: 42px;
  }
  .notification-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #fafafa;
  }
}

@media (max-width: 576px) {
  .notification {
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: auto;
    .notification-wrapper {
      border-radius: 20px 20px 0 0;
      border: 1px solid #28292b;
      border-bottom: 0;
      padding: 20px;
      transform: none;
    }
  }
}
