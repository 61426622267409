.home-page {
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #1c1d1f;
  z-index: 0;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 15%;
    z-index: -1;
    background: url('../../assets/images/Pattern.png') #1c1d1f;
    background-size: 26px 26px;
  }
  .home-page-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 40px;
  }
  .home-page-buttons-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .home-page-button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      height: 50px;
      width: 280px;
      border-radius: 999px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      transition: 0.3s;
      border: none;
      background-color: #FFFFFF;
      color: #1C1D1F;
      &:hover {
        opacity: .9;
      }
      &:active {
        transform: scale(.95);
      }
    }
  }
  .home-page-bottom {
    box-sizing: border-box;
    width: 100%;
    padding: 0 50px;
    .home-page-image {
      width: 100%;
      height: 40vh;
      display: flex;
      justify-content: center;
      background: url('../../assets/images/home-image.jpg') center center no-repeat;
      background-size: cover;
      border-radius: 30px;
      border: 1px solid #28292b;
    }
  }
  .home-page-heading {
    font-size: 96px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #fafafa;
  }
  .home-page-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #fafafa;
  }
  .home-page-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px;
  }
  .home-page-footer-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #FAFAFA;
    opacity: 0.7;
  }
  .home-page-footer-logo-box {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .home-page-footer-logo {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 20px;
    background: #FFFFFF0D;
    transition: all .5s;
    text-decoration: none;
    &:hover {
      background: #FFFFFF;
      .home-page-footer-text {
        color: #1D1E1F;
        opacity: 1;
      }
    }
  }
  .home-page-footer-links {
    display: flex;
    gap: 30px;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .home-page{
    width: 100vw;
    overflow-y: scroll;
    .home-page-top{
      padding: 40px 20px;
    }
    .home-page-bottom{
      padding: 0 20px 60px;
      .home-page-image{
        height: 34.93rem;
      }
      .home-page-footer{
        padding: 40px 30px;
        flex-direction: column;
        .home-page-footer-links{
          width: 100%;
          flex-direction: column;
          .mobile-links{
            display: flex;
            margin-top: 20px;
            gap: 20px;
          }
          .home-page-footer-logo-box{
            width: 100%;
            flex-direction: column;
            gap: 15px;
            .home-page-footer-logo{
              width: 100%;
            }
          }
        }
      }
    }
    .home-page-buttons-box{
      flex-direction: column;
    }
    .home-page-heading{
      font-size: 36px;
      line-height: normal;
    }
    .home-page-text{
      font-size: 16px;
      padding: 0 20px;
    }
  }
}
