.applied-coupon-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .applied-coupon-modal-icon {
    margin-bottom: 30px;
  }
  .applied-coupon-modal-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #FF60B7;
    margin-bottom: 10px;
  }
  .applied-coupon-modal-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .applied-coupon-modal-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .applied-coupon-modal-button-skip {
    border: none;
    background: #404144;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #FAFAFA;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      opacity: .8;
    }
    &:active {
      transform: scale(.95);
    }
  }
  .applied-coupon-modal-button {
    height: 50px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}
