.gallery-page {
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: #1c1d1f;
  z-index: 0;
  overflow-x: hidden;
  position: relative;
  padding: 80px;
  color: #fafafa;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 71px;
    width: 100%;
    height: 100%;
    opacity: 15%;
    z-index: -1;
    background: url('../../assets/images/Pattern.png') #1c1d1f;
    background-size: 26px 26px;
  }

  .gallery-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid #28292b;
  }

  .gallery-page-buttons {
    background: #262729;
    border: 1px solid #404144;
    border-radius: 10px;
    overflow: hidden;
  }

  .gallery-page-button {
    background: transparent;
    border: none;
    width: 217px;
    height: 50px;
    color: #fafafa;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    opacity: 0.5;
    cursor: pointer;
    &.active {
      background: #fafafa;
      color: #262729;
      opacity: 1;
    }
  }

  .gallery-page-content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .gallery-page-empty-content-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    .gallery-page-empty-content {
      box-sizing: border-box;
      max-width: 360px;
      padding: 40px;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      border: 1px solid #28292b;
      background: #1c1d1f;
      box-shadow:
        0 1.51px 2.27px 0 #1c1d1f24,
        0 3.82px 5.73px 0 #1c1d1f34,
        0 7.8px 11.7px 0 #1c1d1f41,
        0 16.06px 24.09px 0 #1c1d1f51,
        0 44px 66px 0 #1c1d1f75;
      .gallery-page-empty-content-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        color: #FF60B7;
      }
      .gallery-page-empty-content-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: center;
      }
      .gallery-page-empty-content-button {
        width: 100%;
        height: 50px;
        padding: 5px 20px;
        color: var(--dino-dark-grey);
        gap: 10px;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        font-family: Outfit, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.02em;
      }
    }
  }

  .mail-cards-box {
    padding-top: 40px;
    display: grid;
    column-gap: 20px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    .mail-card-image {
      height: 22.64vw;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 10px;
      background-size: cover;
      background-position: top;
      background-color: #404144;
    }
    .mail-card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }
    .mail-card-user {
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    .mail-card-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #ff60b7;
    }
    .mail-card-button {
      padding: 8px 20px;
      border-radius: 999px;
      background: #404144;
      border: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #fafafa;
      cursor: pointer;
      transition: 0.2s;
      &:active {
        transform: scale(0.95);
      }
    }
  }
}

@media (max-width: 576px) {
  .gallery-page{
    height: 100%;
    padding: 20px 0 100px;
    &:before {
      left: 0;
    }
    .gallery-page-header{
      padding: 10px 20px;
      border-top: 1px solid #28292b;
      border-bottom: 1px solid #28292b;
      h1{
        display: none;
      }
      .gallery-page-buttons{
        width: 100%;
        display: flex;
        button{
          width: 100%;
        }
      }
    }
    .gallery-page-content-box{
      .mail-cards-box{
        display: flex;
        flex-direction: column;
        padding: 0 20px 20px;
        .mail-card{
          display: flex;
          flex-direction: column;
          height: 18.35rem;
          gap: 10px;
          .mail-card-image{
            height: 90%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
